import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, afterNextRender } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { URL_SERVICEIO } from '../../../config/config';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject = new BehaviorSubject<string | null>(null);
  token$ = this.tokenSubject.asObservable();
  private userSubject = new BehaviorSubject<any>(null);
  user$ = this.userSubject.asObservable();
  constructor(
    public http:HttpClient,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.initAuth();
  }
  initAuth() {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      if (token) {
        this.tokenSubject.next(token);
      }
      if (user) {
        this.userSubject.next(JSON.parse(user));
      }
    }
  }
  login(email:string, password:string){
    let url=URL_SERVICEIO+"/auth/login";
    return this.http.post(url,{email,password}).pipe(
      map((resp: any) => {
        const result=this.saveLocalStorage(resp);
        return result;
      }),
      catchError((err:any) =>{
        return of(err);
      })
    )
  }

  saveLocalStorage(resp:any){
    if (isPlatformBrowser(this.platformId) && resp && resp.access_token) {
      localStorage.setItem('user', JSON.stringify(resp.user));
      localStorage.setItem('token', resp.access_token);
      this.tokenSubject.next(resp.access_token);
      this.userSubject.next(resp.user);
      return true;
    }
    return false;
  }

  register(data:any){
    let url=URL_SERVICEIO+"/auth/register";
    return this.http.post(url, data);
  }
  verifiedAuth(data:any){
    let url=URL_SERVICEIO+"/auth/verifiedAuth";
    return this.http.post(url, data);
  }
  verifiedMail(data:any){
    let url=URL_SERVICEIO+"/auth/forgot_password";
    return this.http.post(url, data);
  }
  verifiedCode(data:any){
    let url=URL_SERVICEIO+"/auth/verifiedCodeForgot";
    return this.http.post(url, data);
  }
  verifiedNewPass(data:any){
    let url=URL_SERVICEIO+"/auth/newPassword";
    return this.http.post(url, data);
  }

  logout(){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
    this.tokenSubject.next(null);
    this.userSubject.next(null);

    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 500);  
  }
  updateInfo(data:any){
    let headers = new HttpHeaders({'Authorization': `Bearer ${this.tokenSubject.value}`});
    let URL = URL_SERVICEIO+"/auth/updateInfo"; 
    //return this.http.post(URL,data,{headers: headers});

    return this.http.post(URL,data,{headers: headers}).pipe(
      map((resp: any) => {
        if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("user",JSON.stringify(resp.user));
        }
        return true;
      }),
      catchError((err:any) =>{
        return of(err);
      })
    )
  }
  getOrder(page:number,user:number){
    let URL = URL_SERVICEIO+"/frontEnd/orders/user/"+user+"?page="+page;
    return this.http.get(URL); 
  }
}
